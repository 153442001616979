/* FONTS */
@import 'fonts.scss';

$theme: 'darker'; // default, dark, light

$backgroundColorHighlight: #1f2124;
$backgroundColorSelected: #282b2e;
$backgroundColor: #1c1c1c;
$backgroundColorDark: #131313;
$backgroundColorDarkHover: #1b1c1f;
$backgroundColorDarker: #060707;
$backgroundColorHover: #151516;
$backgroundtranslucentColor: #282a2e98;
$backgroundMenuColor: #171717;
$backgroundUserPanelColor: #151516;

$forgroundTextInputColor: #a3a3a3;
$forgroundTextColor: #cacaca;
$forgroundTextColorAlt: #686868;
$forgroundTextColorBold: white;
$primaryColor: #1a8e78;
$primaryColorLight: #1ebb9e;
$primaryColorDark: #07473c;
$secondaryColor: #2393f8;
$inputLabelColor: #ebebeb;
$primaryFont: "Nunito";
$headlineFont: "Poppins";

@if ($theme == 'darker') {
  $backgroundColorHighlight: #1f2124;
  $backgroundColorSelected: #282b2e;
  $backgroundColor: #1c1c1c;
  $backgroundColorDark: #131313;
  $backgroundColorDarkHover: #1b1c1f;
  $backgroundColorDarker: #060707;
  $backgroundColorHover: #151516;
  $backgroundtranslucentColor: #282a2e98;
  $backgroundMenuColor: #171717;
  $backgroundUserPanelColor: #151516;

  $forgroundTextInputColor: #a3a3a3;
  $forgroundTextColor: #cacaca;
  $forgroundTextColorAlt: #686868;
  $forgroundTextColorBold: white;
  $primaryColor: #1a8e78;
  $primaryColorLight: #1ebb9e;
  $primaryColorDark: #07473c;
  $secondaryColor: #2393f8;
  $inputLabelColor: #ebebeb;
}

@if ($theme == 'dark') {
  $backgroundColorHighlight: #3f4349;
  $backgroundColorSelected: #4b5057;
  $backgroundColor: #36393F;
  $backgroundColorDark: #202225;
  $backgroundColorDarkHover: #1b1c1f;
  $backgroundColorDarker: #1c1d1f;
  $backgroundColorHover: #32353a;
  $backgroundtranslucentColor: #3f424998;
  $backgroundMenuColor: #2F3136;
  $backgroundUserPanelColor: #28292e;
  $forgroundTextInputColor: #a3a3a3;
  $forgroundTextColor: #cacaca;
  $forgroundTextColorAlt: #686868;
  $forgroundTextColorBold: white;
  $primaryColor: #1a8e78;
  $primaryColorLight: #1ebb9e;
  $primaryColorDark: #07473c;
  $secondaryColor: #2393f8;
  $inputLabelColor: #ebebeb;
}

@if ($theme == 'light') {
  $backgroundColorHighlight: #e9e9e9;
  $backgroundColorSelected: #e7e7e7;
  $backgroundColor: #f1f0f0;
  $backgroundColorDark: #d4d4d4;
  $backgroundMenuColor: #e7e7e7;
  $backgroundColorDarkHover: #e9e9e9;
  $backgroundColorDarker: #e9e9e9;
  $backgroundColorHover: #e0e0e0;
  $backgroundtranslucentColor: #e9e9e998;
  $backgroundUserPanelColor: #cecece;

  $forgroundTextInputColor: #414141;
  $forgroundTextColor: #333333;
  $forgroundTextColorAlt: #686868;
  $forgroundTextColorBold: black;
  $primaryColor: #1a8e78;
  $primaryColorLight: #1ebb9e;
  $primaryColorDark: #07473c;
  $secondaryColor: #2393f8;
  $inputLabelColor: #1b1b1b;
}
