// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "~@angular/material" as mat;
// Plus imports for other components in your app.
@import "~@angular/material/theming";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "variables.scss";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$config: mat-typography-config(
  $font-family: "Poppins",
);
//@include mat.core($config);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$art-dominion-client-primary: mat.define-palette(mat.$cyan-palette);
$art-dominion-client-accent: mat.define-palette(
  mat.$cyan-palette,
  A200,
  A100,
  A400
);
$art-dominion-client-warn: mat.define-palette(mat.$red-palette);
$art-dominion-client-typography: mat.define-typography-config(
  $font-family: "Poppins",
);

$art-dominion-client-typography-heading: mat.define-typography-config(
  $font-family: "Nunito",
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
/*$art-dominion-client-theme: mat.define-dark-theme((
  color: (
    primary: $art-dominion-client-primary,
    accent: $art-dominion-client-accent,
    warn: $art-dominion-client-warn,
  )
));*/

$my-theme: mat-light-theme(
  $art-dominion-client-primary,
  $art-dominion-client-accent
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($art-dominion-client-theme);

/* You can add global styles to this file, and also import other style files */

.my-theme {
  @include angular-material-theme($my-theme);
  .mat-typography & {
    font-size: mat-font-size($art-dominion-client-typography, body-1);
    font-family: mat-font-family($art-dominion-client-typography, body-1);
    line-height: mat-line-height($art-dominion-client-typography, body-1);

    h1,
    h2,
    h3 {
      font-family: mat-font-family(
        $art-dominion-client-typography-heading,
        body-1
      );
    }
  }
}

body {
  background-color: $backgroundColor;
  color: $forgroundTextColor;
  font-family: $primaryFont !important;

  overflow: hidden;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font-family: $primaryFont !important;
}

.mat-simple-snackbar-action {
  color: $primaryColor !important;
}

h1 {
  margin-bottom: 20px;
  text-align: center;
}

h5 {
  text-align: center;
}

.color-forground {
  color: $forgroundTextColor;
}
.color-forground-bold {
  color: $forgroundTextColorBold;
}

.form-group {
  margin-bottom: 25px;

  label {
    font-size: 12px;
    display: block;
    color: $inputLabelColor;
    font-weight: bold;
  }
  input {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    color: $forgroundTextInputColor;
    background-color: $backgroundMenuColor;
    border: 1px solid $backgroundColorDark;
    -webkit-transition: border-color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out;
    padding: 10px;
    margin-top: 9px;
  }
  a {
    font-size: 14px;
    margin-left: 11px;
    margin-top: 3px;
  }
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn {
  color: $forgroundTextColor;
  background-color: $primaryColor;
}
.btn:hover {
  color: $forgroundTextColor;
  background-color: $primaryColorDark;
}

.subtle-form-link {
  font-size: 14px;
}

a,
a:visited,
a:active {
  color: $primaryColor;
}
a:hover {
  color: $primaryColorDark;
}

input:focus-visible,
textarea:focus-visible,
input:focus,
textarea:focus,
.form-select:focus,
.form-select:focus-visible {
  border-radius: 4px;
  box-shadow: 0 0 0 1pt $primaryColor;
  border: 1px solid $primaryColor;
  outline: none;
  transition: 0.3s;
}

.no-avatar {
  background-color: #1a8e78;
  width: 40px;
  display: block;
  position: absolute;
  height: 40px;
  border-radius: 50%;
  .initials {
    position: absolute;
    font-size: 18px;
    top: 10px;
    left: 9px;
    color: white;
  }
}

.descriptionText {
  color: $forgroundTextColorAlt;
}

.popup-wrapper-whole {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
}

.popup-wrapper-width {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 570px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.popup-box {
  background-color: $backgroundColor;
  width: 480px;
  padding: 32px;
  font-size: 18px;
  -webkit-box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.background {
  background-color: $backgroundColorDark;
}

.ad-select {
  background-color: $backgroundMenuColor;
  color: $primaryColor;
  border: 1px solid $backgroundColorDark;
  border-radius: 4px;
  padding: 6px 20px;
  margin-bottom: 15px;
}

.form-select:disabled,
input:disabled {
  background-color: $backgroundMenuColor;
}

.terms-text {
  font-size: 10px;
  color: #616161;
}

.text-center {
  text-align: center;
}

.g-recaptcha {
  display: inline-block;
}

.error-message {
  color: red !important;
  font-weight: 100;
  font-size: 14px;
}

.success-message {
  color: greenyellow !important;
  font-weight: 100;
  font-size: 14px;
}

.text-center {
  text-align: center;
  display: block;
}

.capcha-container {
  text-align: center;
}

re-captcha {
  display: inline-block;
}

.bi-envelope-fill {
  margin: auto;
  display: block;
  margin-bottom: 30px;
  color: $primaryColor;
}

.bi {
  margin: auto;
  display: block;
  margin-bottom: 30px;
}

.bi-check-lg {
  color: $primaryColor;
}

.bi-exclamation-circle {
  color: orange;
}

button {
  a {
    color: white !important;
    text-decoration: none;
  }
}

.tooltip {
  font-size: 14px;
  font-weight: bold;
  z-index: 99999999;
}

.bs-tooltip-right {
  margin-left: 10px;
  z-index: 9999;
}

.tooltip-inner {
  white-space: nowrap;
  max-width: none;
  padding: 10px;
  z-index: 9999;
}

.scroll {
  overflow-x: hidden;
  overflow-y: scroll;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: scroll;
}

.mat-expansion-panel-body,
.mat-list-base {
  padding: 0 !important;
}

/* Scrollbars */
/* Works on Firefox */
* {
  scrollbar-width: 4px;
  scrollbar-color: $backgroundColorDark;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

/**::-webkit-scrollbar-track {
  //background: orange;
}*/

*::-webkit-scrollbar-thumb {
  background-color: $backgroundColorDark;
  border-radius: 20px;
  border: 1px solid $backgroundtranslucentColor;
}

.leftscrollbar {
  overflow-x: hidden;
  overflow-y: scroll;
  direction: rtl;
}

.leftscrollbar > div {
  direction: ltr;
}

.scroll-chat {
  scrollbar-width: 8px;
}

.scroll-chat::-webkit-scrollbar {
  width: 8px;
}

.vr {
  position: absolute;
  left: 130px;
  top: 12px;
  height: 55%;
  width: 1px;
  background-color: currentColor;
  opacity: 0.07;
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  100% {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scaleOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    visibility: hidden;
    transform: scale(0.1);
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(40);
  }
  100% {
    visibility: visible;
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    transform: translateX(40);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.scalein {
  animation: 0.15s scaleIn;
  animation-fill-mode: forwards;
  display: block !important;
}

.scaleout {
  animation: 0.15s scaleOut;
  animation-fill-mode: forwards;
}

.fadein {
  animation: 0.15s fadeIn;
  animation-fill-mode: forwards;
  display: block !important;
}

.fadeout {
  animation: 0.15s fadeOut;
  animation-fill-mode: forwards;
}

* {
  // No user selection
  user-select: none;
}

input.switcher {
  position: relative;
  appearance: none;
  outline: none;
  width: 50px;
  height: 30px;
  background-color: white;
  border: 1px solid $forgroundTextInputColor;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 $forgroundTextInputColor;
  transition-duration: 200ms;
}

input.switcher:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 26px;
  height: 26px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.4);
}

input.switcher:checked {
  border: 1px solid $primaryColor;
  box-shadow: inset 20px 0 0 0 $primaryColor;
}

input.switcher:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.25);
}

// radio buttons for popups
.popup-radio {
  input:checked + label {
    background-color: $backgroundColorDark;

    .radio-display-dot {
      background-color: $primaryColorLight;
      border: 4px solid $backgroundMenuColor;
    }
  }

  input {
    position: fixed;
    opacity: 0;
    pointer-events: none;
  }

  label {
    cursor: pointer;
    position: relative;
    display: block;
    width: 100%;
    height: 50px;
    background-color: $backgroundMenuColor;
    border-radius: 5px;
    margin: 10px 0;
    border: 2px solid $backgroundUserPanelColor;

    .radio-display {
      background-color: white;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      float: left;
      margin-left: 12.5px;
      margin-top: 12.5px;
    }
    .radio-display-dot {
      background-color: $backgroundMenuColor;
      border: 4px solid $backgroundMenuColor;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      float: left;
      margin-left: 2px;
      margin-top: 2px;
    }
    .icon {
      float: left;
      width: 30px;
      height: 100%;
      margin-left: 5px;
      margin-top: 8px;
      margin-right: 5px;
      color: $forgroundTextInputColor;
      .bi-brush-fill {
        margin-top: 4px;
      }
    }
    .information {
      line-height: 1.4;
      .title {
        font-weight: 100;
        font-size: 15px;
        margin-top: 6px;
      }
      .description {
        font-size: 11px;
        font-weight: 100;
      }
    }
  }
  label:hover {
    background-color: $backgroundUserPanelColor;
  }
}

.canvas-fileover {
  .fileover {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    //display: none;
    .drop-area {
      text-align: center;
      width: 300px;
      height: 300px;
      border-radius: 30px;
      border: 2px grey dashed;
      color: grey;
    }
  }

  .fileover-activate {
    display: block;
  }
}

.valign {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  /* vendor prefixes omitted due to brevity */
}

.cursor {
  width: 1px;
  height: 20px;
  background-color: white;
  position: absolute;
  margin-left: 5px;
  opacity: 1;
  animation: blink-animation 700ms infinite;
  transform: scale(1);
}

@keyframes blink-animation {
  to {
    opacity: 0.2;
    transform: scale(0.6);
  }
}

.hide-servers {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  backdrop-filter: blur(6px);
  transition: 300ms;
}

.fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000099;
  z-index: 100;
  margin: 0;
  padding: 0;
  display: none;
  backdrop-filter: blur(6px);
  transition: 300ms;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.btn {
  transition: 200ms;
}

.btn-danger {
  color: white;
  background-color: rgb(161, 0, 0);
  border-color: red;

  &:hover {
    background-color: red;
  }
}

.btn-cancel {
  color: #8f8f8f;
  background-color: #3a3a3a;
  border-color: #4f4f4f;

  &:hover {
    background-color: #4e4e4e;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: $primaryFont;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-simple-snackbar span {
  margin: auto;
  text-align: center;
}

*::selection {
  background: $primaryColor;
  color: white;
}
*::-moz-selection {
  background: $primaryColor;
  color: white;
}
/*
@keyframes spin {
  from {
    //transform: rotate(0deg);
  }
  to {
    //transform: rotate(359deg);
  }
}

@keyframes spin-backwards {
  from {
    //transform: rotate(359deg);
  }
  to {
    //transform: rotate(0deg);
  }
}
*/
